@import '../_variables.scss';

.baseLayout {
  & > div {
    transition: filter 0.15s linear;
  }

  &.navOpen {
    & > div:not(.mobileMenu, .navbar) {
      filter: blur(15px);
    }
  }
}