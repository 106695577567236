@import './_variables.scss';
@import './animations.scss';

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir Medium'), local('Avenir-Medium'), url(/fonts/Avenir/Avenir-Medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: local('Avenir Bold'), local('Avenir-Bold'), url(/fonts/Avenir/Avenir-Heavy.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
  font-family: 'Trajan Pro 3';
  font-style: normal;
  font-weight: 600;
  src: local('Avenir Bold'), local('Avenir-Bold'), url(/fonts/Trajan/TrajanPro3Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
  font-family: 'Trajan Pro 3';
  font-style: normal;
  font-weight: 500;
  src: local('Avenir Bold'), local('Avenir-Bold'), url(/fonts/Trajan/TrajanPro3SemiBold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}

body {
	font-family: 'Avenir', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	overscroll-behavior: none;
	background-color: transparent;
	-webkit-overflow-scrolling: touch;
	color: $textColor;
	background-color: $mainBackground;
	margin: 0;
	padding: 0;
}

html, html a, html input, html button {
	-webkit-font-smoothing: antialiased;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
}

*:hover {
	&>.tooltip {
		display: flex;
	}  
}

.tooltip {
	//display: flex;
	display: none;
	position: absolute;
	left: 0px;
	bottom: 28px;
	transform: translate(0%, 0%);
	width: 210px;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 4px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	letter-spacing: 0.25px;
	color: #FFFFFF;
	&.center {
		left: 50%;
		transform: translate(-50%, 0%);
	}
	&.left {
		left: initial;
		right: 0px;
		transform: translate(0%, 0%);
	}
	&.bottom {
		bottom: initial;
		top: 28px;
	}
}

.appContent {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	z-index: 1;
	background-color: $mainBackground;
	&>* {
		flex-shrink: 0;
	}
}

a {
  color: $primaryColor;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover {
    color: #71b0ab;
  }
}

:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
	font-family: 'Trajan Pro 3';
	font-weight: 500;
}

button {
	display: block;
  background: $primaryColor;
  border: none;
  color: white;
  width: 100%;
	max-width: 240px;
  font-weight: bold;
  cursor: pointer;
  border-radius: $borderRadiusMain;
  padding: 18px 24px 17px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s linear;
  &:hover {
    background-color: $primaryColorHover;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.25);
  }
	&:disabled {
		cursor: default;
		background-color: $accentColor;
		box-shadow: none;
		color: rgba(255, 255, 255, 0.5);
	}
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
	padding: 0 30px;

  @media all and (min-width: 576px) {
    max-width: 540px;
		padding: 0;
  }
  @media all and (min-width: 768px) {
    max-width: 720px;
  }
  @media all and (min-width: 992px) {
    max-width: 980px;

		&.small {
			width: 880px;
		}
  }
}

.text-center {
	text-align: center;
}

/* Slick */
.slick-slider {
	.slick-dots {
		padding-left: 0;
		
		li {
			&.slick-active {
				button {
					background-color: #55AEB2 !important;
				}
			}
		}
	}
}