@import '../_variables.scss';

.header {
  .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 99;
    padding-top: $spacingBase;
    background-color: transparent;
    position: fixed;
    top: 0;
  }

  &.sticky {
    .navbar {
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
      background-color: $mainBackground;
      padding: 10px 0;
      height: 60px;

      @media all and (max-width: $break-point-md) {
        height: 90px;
      }

      .logo {
        height: 32px;

        @media all and (max-width: $break-point-md) {
          height: 40px;
        }
      }

      .mintBtn {
        height: 38px;

        @media all and (max-width: $break-point-xs) {
          height: 50px;
        }
      }
    }
    &.navOpen {
      .navbar {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  &.navOpen {
    filter: none !important;
    
    .navbar {
      filter: none !important;

      .navToggle {
        &:before {
          transform: rotate(45deg);
          top: -4px;
        }
        &:after {
          transform: rotate(-45deg);
          top: -4px;
        }
      }
    }

    .mobileMenu {
      height: 100%;
      display: block;
      filter: none !important;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-wrap: wrap;
    }
  }

  .logo {
    width: 143px;
    height: 40px;
    background-image: url('/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0;
    transition: all 0.15s linear;
    cursor: pointer;
  
    @media 
      (-webkit-min-device-pixel-ratio: 2), 
      (min-resolution: 192dpi) {
      background-image: url('/images/logo@2x.png');
    }
  }

  .contacts {
    display: flex;
    align-items: center;

    a,
    span {
      display: flex;
      align-items: center;
      width: 36px;
      height: 36px;
      position: relative;
      margin-right: 35px;
      cursor: pointer;

      &.linkBtn {
        width: auto;
        height: auto;
        font-weight: bold;
      }

      &:before {
        content: '';
        display: block;
        background-color: rgba(255, 255, 255, 0.75);
        margin: 0 auto;
        transition: all 0.2s linear;
      }

      &:hover {
        &:before {
          background-color: rgba(255, 255, 255, 1);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .opensea {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/opensea.svg) no-repeat center;
        mask-size: 30px;
      }
    }

    .discord {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/discord-square.svg) no-repeat center;
        mask-size: 30px;
      }
    }

    .twitter {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/twitter.svg) no-repeat center;
        mask-size: 30px;
      }
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 99;
    padding-top: $spacingBase;
    background-color: transparent;
    position: fixed;
    top: 0;

    .navToggle {
      display: none;
      position: relative;
      width: 30px;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #FFF;
        transition: all 0.15s linear;
      }

      &:before {
        top: -9px;
      }

      &:after {
        top: 6px;
      }
    }

    @media all and (max-width: $break-point-xs) {
      .navToggle {
        display: block;
      }
      .contacts {
        display: none;
      }
    }
  }

  .mobileMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    padding-top: 120px;
    background-color: rgba(255, 255, 255, 0.05);
    z-index: 98;
    transition: all 0.2s linear;
    overflow: hidden;
    display: none;

    .menuItem {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      .menuLink {
        cursor: pointer;
        font-size: $fontSizeTitle;
        font-weight: bold;
        position: relative;

        span {
          width: 30px;
          height: 30px;
          position: absolute;
          display: block;
          background-color: rgba(255, 255, 255, 1);
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
    
        .opensea {
          mask: url(/images/icons/opensea.svg) no-repeat center;
          mask-size: 30px;
        }
    
        .discord {
          mask: url(/images/icons/discord-square.svg) no-repeat center;
          mask-size: 30px;
        }
    
        .twitter {
          mask: url(/images/icons/twitter.svg) no-repeat center;
          mask-size: 30px;
        }
      }
    }
  }
}