// Media Settings
$break-point-lg: 1400px;
$break-point-md: 1240px;
$break-point-sm: 905px;
$break-point-xs: 600px;

// Color variables
$primaryColor: #00ADB3;
$primaryColorActive: #00ADB3;
$primaryColorHover: #22bdc2;
$loadingColor: #95CAB4;
$accentColor: #484873;
$contrastAccentColor: #FC007D;

$secondaryColor: #C4C4E1;
$thirdColor: #5252AB;
$fourthColor: #9696C6;

$mainBackground: #070619;
$secondaryBackground: #333354;

$successColor: #3EC254;
$successBorderColor: #95CAB4;
$successBackgroundColor: #F0F8F5;
$errorColor: #D72C0E;
$errorBorderColor: #E0B3B2;
$errorBackgroundColor: #FEF4F4;

$disabledColor: rgba(0, 0, 0, 0.05);

$textColor: #FFFFFF;
$textTitleColor: rgba(0, 0, 0, 0.87);
$textSubtleColor: rgba(0, 0, 0, 0.38);
$highlightColor: rgba(0, 0, 0, 0.12);

$borderRadiusMain: 10px;
$borderRadiusSmall: 5px;

/* Typography */
$fontSizeHeader: 2.25rem; // 36px / 16px
$fontSizeTitle: 1.5rem; // 24px / 16px
$fontSizeBasePlus: 1.125rem; // 18px / 16px
$fontSizeBase: 16px;
$fontSizeSmall: 0.875rem; // 14px / 16px

/* Spacings */
$spacingHuge: 60px;
$spacingBig: 40px;
$spacingBasePlus: 30px;
$spacingBase: 25px;
$spacingSmall: 15px;
$spacingMicro: 10px;
$spacingNano: 5px;