@import '../_variables.scss';

.footer {
  .flex {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 25px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-wrap: wrap;
    }
    
    @media all and (max-width: $break-point-xs) {
      .logo {
        display: none;
      }
      .contacts {
        display: none;
      }
    }

    .copyrights {
      color: $secondaryColor;
    }
  }

  .logo {
    width: 143px;
    height: 40px;
    background-image: url('/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0;
    transition: all 0.15s linear;
  
    @media 
      (-webkit-min-device-pixel-ratio: 2), 
      (min-resolution: 192dpi) {
      background-image: url('/images/logo@2x.png');
    }
  }

  .contacts {
    display: flex;
    align-items: center;

    a,
    span {
      display: flex;
      align-items: center;
      width: 36px;
      height: 36px;
      position: relative;
      margin-right: 35px;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        background-color: rgba(255, 255, 255, 0.75);
        margin: 0 auto;
        transition: all 0.2s linear;
      }

      &:hover {
        &:before {
          background-color: rgba(255, 255, 255, 1);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .opensea {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/opensea.svg) no-repeat center;
        mask-size: 30px;
      }
    }

    .discord {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/discord-square.svg) no-repeat center;
        mask-size: 30px;
      }
    }

    .twitter {
      @media all and (max-width: $break-point-xs) {
        display: none;
      }

      &:before {
        width: 30px;
        height: 30px;
        mask: url(/images/icons/twitter.svg) no-repeat center;
        mask-size: 30px;
      }
    }
  }
}